module.exports = function() {
    return {
        STRID_ITEM_SEND_MESSAGE: 'メッセージ送信',
        STRID_SEARCH_CLEAR_PARAMS: '検索条件をリセット',
        STRID_SEARCH_EXEC: 'この条件で検索',
        STRID_SEARCH_ITEM_PULLDOWN_ALL: 'すべて',
        STRID_SEARCH_ITEM_LOCATION: '拠点名',
        STRID_UPLOAD_EXCEL_COMMON_NOFILE: 'Excelファイルを選択してください。',
        STRID_UPLOAD_EXCEL_COMMON_REMOVED: '選択されたExcelファイルが存在しません。',
        STRID_UPLOAD_EXCEL_COMMON_EXT_INVALID: '対応したExcelファイルではありません。',
        STRID_UPLOAD_EXCEL_COMMON_COLUMN_COUNT_MISMATCH: '項目数が不正です。',
        STRID_UPLOAD_EXCEL_COMMON_COLUMN_INVALID_ORDER: '項目順が不正です。',
        STRID_UPLOAD_EXCEL_COMMON_FORMAT_INVALID: 'Excelフォーマットが不正です。',
        STRID_UPLOAD_EXCEL_COMMON_MULTIPLE_COMPANY: '複数法人を同時に指定できません。',
        STRID_UPLOAD_EXCEL_COMMON_COMPANY_NOT_EXISTS: '{line}行目の法人IDは存在しません。',
        STRID_UPLOAD_EXCEL_COMMON_COMPANY_CODE_INVALID: '{line}行目の法人IDが不正です。',
        STRID_UPLOAD_EXCEL_COMMON_COMPANY_CODE_EMPTY: '{line}行目の法人IDが未入力です。',
        STRID_UPLOAD_EXCEL_COMMON_LOCATION_CODE_EMPTY: '{line}行目の拠点IDが未入力です。',
        STRID_UPLOAD_EXCEL_COMMON_LOCATION_CODE_INVALID: '{line}行目の拠点IDが不正です。',
        STRID_UPLOAD_EXCEL_COMMON_LOCATION_NOT_EXISTS: '{line}行目の拠点は存在しません。',
        STRID_UPLOAD_EXCEL_COMMON_DELFLAG_INVALID: '{line}行目の削除フラグが不正です。',
        STRID_BUTTON_LABEL_CANCEL: 'キャンセル',
        STRID_BUTTON_LABEL_REGISTER: '登録',
        STRID_BUTTON_LABEL_YES: 'はい',
        STRID_BUTTON_LABEL_NO: 'いいえ',
        STRID_LICENSE_TYPE_MOBILE: '携帯型利用ライセンス',
        STRID_LICENSE_TYPE_STATIONARY: '据置型利用ライセンス',
        STRID_LICENSE_TYPE_ALPIT_STANDARD: 'ALPiT利用ライセンス',
        STRID_LICENSE_TYPE_ALPIT_PRO: 'ALPiT利用ライセンス（電気化学式）',
        STRID_TABLE_HEAD_LOCATION_NAME: '拠点名',
        STRID_UPLOAD_EXCEL_LOCATION_CODE_DUPLICATE_ENTRY: '{line}行目の拠点IDがExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_LOCATION_NAME_DUPLICATE_ENTRY: '{line}行目の拠点名がExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_LOCATION_NO_PERMISSION: '{line}行目の拠点IDが不正です。拠点管理者は自身が所属する拠点の更新のみができます。',
        STRID_UPLOAD_EXCEL_LOCATION_DELETE_NOT_EXIST: '{line}行目の拠点IDが存在しない拠点に対して削除フラグが1(削除)で指定されています。',
        STRID_UPLOAD_EXCEL_DELETE_LOCATION_DELETE_WITHOUT_TARGET: '{line}行目で拠点IDが指定されずに削除フラグが1(削除)で指定されています。',
        STRID_UPLOAD_EXCEL_LOCATION_UPDATE_NOT_EXIST: '{line}行目の拠点IDが存在しない拠点に対して削除フラグが0(新規追加または更新)で指定されています。',
        STRID_UPLOAD_EXCEL_LOCATION_NAME_EXISTS: '{line}行目の拠点名は既に登録されています。',
        STRID_UPLOAD_EXCEL_LOCATION_NAME_EMPTY: '{line}行目の拠点名が未入力です。',
        STRID_UPLOAD_EXCEL_LOCATION_NAME_MAXLEN: '{line}行目の拠点名は50文字以下にしてください。',
        STRID_UPLOAD_EXCEL_LOCATION_NAME_INVALID: '{line}行目の拠点名が不正です。',
        STRID_UPLOAD_EXCEL_LOCATION_CODE_INVALID: '{line}行目の拠点IDが不正です。',
        STRID_UPLOAD_EXCEL_LOCATION_REPRESENTATIVE_NAME_MAXLEN: '{line}行目の代表者名は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_LOCATION_REPRESENTATIVE_NAME_INVALID: '{line}行目の代表者名が不正です。',
        STRID_UPLOAD_EXCEL_LOCATION_PHONE_MAXLEN: '{line}行目の電話番号は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_LOCATION_PHONE_INVALID: '{line}行目の電話番号が不正です。',
        STRID_UPLOAD_EXCEL_LOCATION_EMAIL_MAXLEN: '{line}行目のメールアドレスは255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_LOCATION_EMAIL_INVALID: '{line}行目のメールアドレスが不正です。',
        STRID_UPLOAD_EXCEL_LOCATION_NOTE_MAXLEN: '{line}行目の備考は255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_LOCATION_NOTE_INVALID: '{line}行目の備考が不正です。',
        STRID_UPLOAD_EXCEL_LOCATION_CODE_MAX: '99999件を超える拠点は登録できません。サポートにお問い合わせください。',
        STRID_UPLOAD_EXCEL_LOCATION_HAVING_USERS: '{line}行目は削除できません。 現在使用データがあります。',
        STRID_UPLOAD_EXCEL_USER_ID_DUPLICATE_ENTRY: '{line}行目の管理者IDがExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_USER_EMAIL_DUPLICATE_ENTRY: '{line}行目のメールアドレスがExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_USER_DELETE_NOT_EXIST: '{line}行目の管理者IDが存在しない管理者に対して削除フラグが1(削除)で指定されています。',
        STRID_UPLOAD_EXCEL_DELETE_USER_DELETE_WITHOUT_TARGET: '{line}行目で管理者IDが指定されずに削除フラグが1(削除)で指定されています。',
        STRID_UPLOAD_EXCEL_USER_UPDATE_NOT_EXIST: '{line}行目の管理者IDが存在しない管理者に対して削除フラグが0(新規追加または更新)で指定されています。',
        STRID_UPLOAD_EXCEL_USER_NO_PERMISSION: '{line}行目の管理者IDの管理者はログインアカウントより権限が上のためインポートできません。',
        STRID_UPLOAD_EXCEL_USER_USER_ID_INVALID: '{line}行目の管理者IDが不正です。',
        STRID_UPLOAD_EXCEL_USER_USER_NAME_EMPTY: '{line}行目の氏名が未入力です。',
        STRID_UPLOAD_EXCEL_USER_USER_NAME_MAXLEN: '{line}行目の氏名は255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_USER_ROLEID_INVALID_1: '{line}行目の権限は0(アイリスサポート管理者)か1(管理者)か2(拠点管理者)を指定してください。',
        STRID_UPLOAD_EXCEL_USER_ROLEID_INVALID_2: '{line}行目の権限は1(管理者)か2(拠点管理者)を指定してください。',
        STRID_UPLOAD_EXCEL_USER_ROLEID_INVALID_3: '{line}行目の権限はログインアカウントより上の権限は設定できません。',
        STRID_UPLOAD_EXCEL_USER_LOCATION_CODE_REQUIRED: '{line}行目の拠点IDは拠点管理者には必須です。',
        STRID_UPLOAD_EXCEL_USER_EMAIL_MAXLEN: '{line}行目のメールアドレスは255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_USER_EMAIL_INVALID: '{line}行目のメールアドレスが不正です。',
        STRID_UPLOAD_EXCEL_USER_EMAIL_EXISTS: '{line}行目のメールアドレスは既に登録されています。',
        STRID_UPLOAD_EXCEL_USER_EMAIL_EMPTY: '{line}行目のメールアドレスが未入力です。',
        STRID_UPLOAD_EXCEL_USER_PASSWORD_MAXLEN: '{line}行目のパスワードは255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_USER_PASSWORD_MINLEN: '{line}行目のパスワードは8文字以上にしてください。',
        STRID_UPLOAD_EXCEL_USER_PASSWORD_INVALID: '{line}行目のパスワードが不正です。',
        STRID_UPLOAD_EXCEL_USER_NOTE_MAXLEN: '{line}行目の備考は255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_USER_NOTE_INVALID: '{line}行目の備考が不正です。',
        STRID_UPLOAD_EXCEL_USER_DELFLAG_MYSELF: '{line}行目の管理者でログインしているため、削除はできません。',
        STRID_SEARCH_ITEM_DRIVER_NAME: '氏名',
        STRID_TABLE_HEAD_DRIVER_ID: '運転者ID',
        STRID_UPLOAD_EXCEL_DRIVER_DUPLICATE_ENTRY: '{line}行目の運転者IDがExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_DRIVER_DELETE_NO_TARGET: '{line}行目が存在しない運転者に対して削除フラグが1(削除)で指定されています。',
        STRID_UPLOAD_EXCEL_DRIVER_LICENSE_MAX: '利用できるライセンスの上限に達しました。\\n利用可能件数 {maxcount} 名に対して {calculatedcount} 名指定されています。',
        STRID_UPLOAD_EXCEL_DRIVER_DRIVER_ID_EMPTY: '{line}行目の運転者IDが未入力です。',
        STRID_UPLOAD_EXCEL_DRIVER_DRIVER_ID_MAXLEN: '{line}行目の運転者IDは20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_DRIVER_DRIVER_ID_INVALID: '{line}行目の運転者IDが不正です。',
        STRID_UPLOAD_EXCEL_DRIVER_DRIVER_NAME_EMPTY: '{line}行目の運転者名が未入力です。',
        STRID_UPLOAD_EXCEL_DRIVER_DRIVER_NAME_MAXLEN: '{line}行目の運転者名は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_DRIVER_DRIVER_NAME_INVALID: '{line}行目の運転者名が不正です。',
        STRID_UPLOAD_EXCEL_DRIVER_PHONE_INVALID: '{line}行目の携帯番号が不正です。',
        STRID_UPLOAD_EXCEL_DRIVER_PHONE_MAXLEN: '{line}行目の携帯番号は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_DRIVER_POSITION_MAXLEN: '{line}行目の役職は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_DRIVER_POSITION_INVALID: '{line}行目の役職が不正です。',
        STRID_UPLOAD_EXCEL_DRIVER_MEMO_MAXLEN: '{line}行目の備考は255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_DRIVER_MEMO_INVALID: '{line}行目の備考が不正です。',
        STRID_UPLOAD_EXCEL_DRIVER_ACTIVATE_FLAG_INVALID: '{line}行目の測定対象フラグが不正です。',
        STRID_UPLOAD_EXCEL_VEHICLE_ID_DUPLICATE_ENTRY: '{line}行目の車両IDがExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_VEHICLE_NO_DUPLICATE_ENTRY: '{line}行目の車両番号がExcelデータに重複して存在しています。',
        STRID_UPLOAD_EXCEL_VEHICLE_LOCATION_CODE_REQUIRED: '{line}行目の拠点IDは拠点管理者には必須です。',
        STRID_UPLOAD_EXCEL_VEHICLE_DELETE_NO_TARGET: '{line}行目が存在しない車両に対して削除フラグが1(削除)で指定されています。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHIDLENO_EXISTS: '{line}行目の車両番号は既に登録されています。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_ID_EMPTY: '{line}行目の車両IDが未入力です。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_ID_MAXLEN: '{line}行目の車両IDは20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_ID_INVALID: '{line}行目の車両IDが不正です。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_NO_EMPTY: '{line}行目の車両番号が未入力です。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_NO_MAXLEN: '{line}行目の車両番号は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_NO_INVALID: '{line}行目の車両番号が不正です。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_MODEL_MAXLEN: '{line}行目の車種(車名)は50文字以下にしてください。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_MODEL_INVALID: '{line}行目の車種(車名)が不正です。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_COLOR_MAXLEN: '{line}行目の車の色は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_COLOR_INVALID: '{line}行目の車の色が不正です。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_INSPECTION_PERIOD_MAXLEN: '{line}行目の車検満了日は20文字以下にしてください。',
        STRID_UPLOAD_EXCEL_VEHICLE_VEHICLE_INSPECTION_PERIOD_INVALID: '{line}行目の車検満了日が不正です。',
        STRID_UPLOAD_EXCEL_VEHICLE_NOTE_MAXLEN: '{line}行目の備考は255文字以下にしてください。',
        STRID_UPLOAD_EXCEL_VEHICLE_NOTE_INVALID: '{line}行目の備考が不正です。',
        STRID_RESULT_LOCATION_ADDRESS: '測定場所',
        STRID_BUTTON_LABEL_SETTING_INSTRUCTION: '指示対応の設定',
        STRID_INSTRUCTION_MODAL_TITLE: '指示対応の設定',
        STRID_INSTRUCTION_LABEL_OK: '測定結果OK',
        STRID_INSTRUCTION_LABEL_NG: '測定結果NG',
        STRID_INSTRUCTION_LABEL_NC: '確認者指定なし',
        STRID_INSTRUCTION_LABEL_OPT_1: '指示対応1',
        STRID_INSTRUCTION_LABEL_OPT_2: '指示対応2',
        STRID_INSTRUCTION_LABEL_OPT_3: '指示対応3',
        STRID_INSTRUCTION_LABEL_OPT_4: '指示対応4',
        STRID_BUTTON_LABEL_SAVE: '＋　保存',
        STRID_VALIDATION_CONTAINS_INVALID_CHARACTER: '使用できない文字が含まれています。',
        STRID_VALIDATION_INSTRUCTION_NO_VALUE: '入力してください。',
        STRID_VALIDATION_INSTRUCTION_MAXLEN: '{num}文字以内で設定してください。',
        STRID_VALIDATION_UPDATED_AT_INVALID: 'データの整合性検証に失敗しました。',
        STRID_VALIDATION_ALREADY_MODIFIED: '他のユーザーによって変更されています。再読み込みしてください。',
        STRID_DEFAULT_INSTRUCTION_OK: '安全運転を行うよう指示',
        STRID_DEFAULT_INSTRUCTION_NG: '安全運転管理者に確認の上指示に従うこと',
        STRID_DEFAULT_INSTRUCTION_NC: '特になし',
        STRID_CONFIRM_INSTRUCTION_MESSAGE_SINGLE: '以下で確認いたします。確認内容は編集できません。',
        STRID_INSTRUCTION_LABEL_CONFIRMER: '確認者',
        STRID_INSTRUCTION_LABEL_INSTRUCTION_DETAIL: '指示対応',
        STRID_INSTRUCTION_PULLDOWN_VALUE_NO_CHANGE: '指示対応変更なし',
        STRID_CONFIRM_INSTRUCTION_MESSAGE_MULTI: '以下でまとめて確認いたします。確認内容は編集できません。',
        STRID_VALIDATION_NO_ALCOHOL_RESULT: '指定された測定履歴が見つかりません。',
        STRID_VALIDATION_NO_INSTRUCTION: '指定された指示対応は存在しません。',
        STRID_VALIDATION_NO_ALCOHOL_RESULT_ID: '測定履歴を指定してください。',
        STRID_BUTTON_CONFIRM_SEND_MESSAGE: '送信確認',
        STRID_LABEL_DRIVER: '運転者リスト',
        STRID_SEND_MESSAGE_TABLE_HEAD_DRIVER_NAME: '運転者氏名',
        STRID_LABEL_NO_FCM_TOKEN: '-',
        STRID_LABEL_TARGET_SEND_MESSAGE: '送信者リスト',
        STRID_MESSAGE_NOTIFICATION_UNAVAILABLE: '「－」表示の運転者は以下状態となっている可能性があります。',
        STRID_MESSAGE_NOTIFICATION_COND_1: 'アプリケーションが最新バージョンではない',
        STRID_MESSAGE_NOTIFICATION_COND_2: 'アプリケーション側でログインを行っていない',
        STRID_MESSAGE_CONFIRM_SEND_MESSAGE: '以下のメッセージを運転者に送信しますか？',
        STRID_LABEL_SENDER: '通知者',
        STRID_BUTTON_SEND: '送信',
        STRID_MESSAGE_COMPLETE_SEND_MESSAGE: '送信しました。',
        STRID_MESSAGE_ERROR_SEND_MESSAGE: 'エラーが発生しました。',
        STRID_HTML_CONFIRM_TRANSITION: '移動しますか？<br><br>内容はクリアされます。',
        STRID_VALIDATION_MISSING_IDS_TO_SEND_MESSAGE: '運転者を指定してください。',
        STRID_VALIDATION_MISSING_MESSAGE_BODY: 'メッセージを指定してください。',
        STRID_VALIDATION_MESSAGE_BODY_MAXLEN: 'メッセージは{len}文字以内で指定してください。',
        STRID_VALIDATION_MESSAGE_BODY_MINLEN: 'メッセージは{len}文字以上で指定してください。',
        STRID_TITLE_SEND_MESSAGE: 'ALPiTアプリ',
        FORMAT_SEND_MESSAGE: '{message}({sender})',
        FORMAT_DATE_PHP: 'Y/m/d',
        FORMAT_DATETIME_PHP: 'Y/m/d H:i:s',
        FORMAT_DATE_JS: 'YYYY/MM/DD',
        FORMAT_DATETIME_JS: 'YYYY/MM/DD HH:mm:ss',
    };
};