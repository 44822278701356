<template>
    <Modal
        v-model="showModal"
        modal-class="new-modal send-message-modal confirm-no-title"
        wrapper-class="new-modal-wrapper"
        :enableClose="false"
    >
        <div class="new-modal-content">
            <div v-if="message" class="alert message-area mb-3">
                {{ $_cstr('STRID_MESSAGE_CONFIRM_SEND_MESSAGE') }}
            </div>
            <div class="mb-3">
                <dl class="instruction-confirm-list">
                    <div class="list-item">
                        <dt>
                            <span>
                                {{ $_cstr('STRID_LABEL_SENDER') }}
                            </span>
                        </dt>
                        <dd class="confirmer">
                            {{ confirmerInfo.name }}
                        </dd>
                    </div>
                    <div class="list-item message">
                        {{ message }}
                    </div>
                </dl>
            </div>
        </div>

        <div class="modal-footer mt-2">
            <div class="ms-auto d-flex justify-content-end button-area">
                <div class="button-wrapper">
                    <button class="btn btn-info" type="button" @click="onClose">
                        {{ $_cstr('STRID_BUTTON_LABEL_CANCEL') }}
                    </button>
                </div>
                <div class="button-wrapper">
                    <button class="btn btn-primary" type="button" @click="execute">
                        {{ $_cstr('STRID_BUTTON_SEND') }}
                    </button>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty, isListEmpty, checkAlpitResponse } from '@/composables/common.js';
 import BaseToastComponent from '@/components/common/BaseToastComponent.vue';
 export default {
     mixins: [
         BaseToastComponent,
     ],
     emits: [
         'execSend',
     ],
     props: {
         confirmerInfo: {type: Object, required: true},
         message: {type: String, required: true},
     },
     computed: {
         isDisabled() {
             return this.isLoading;
         },
     },
     data: function() {
         return {
             showModal: false,
         };
     },
     mounted: async function() {
         this.initialize();
     },
     methods: {
         initialize() {
         },
         open() {
             this.initialize();
             this.showModal = true;
         },
         onClose() {
             this.showModal = false;
         },
         execute() {
             this.$emit('execSend');
             this.onClose();
         },
     },
 }
</script>
