<template>
    <div class="toggle-container" :class="$attrs.class">
        <span class="toggle-label">{{ label }}</span>
        <div :class="['toggle-switch',isChecked ? 'on' : '']" @click="toggleSwitch">
            <div :class="['toggle-thumb', isChecked ? 'on' : '']">
            </div>
        </div>
    </div>
</template>

<script>
 export default {
     inheritAttrs: false, // $attrs を使用するために必要
     props: {
         label: {type: String, default:''},
         handlerChange: {type: Function, default: state => {}},
     },
     data() {
         return {
             isChecked: false,
         };
     },
     methods: {
         toggleSwitch() {
             this.isChecked = !this.isChecked;
             this.handlerChange(this.isChecked);
         },
         getState() {
             return this.isChecked;
         },
     },
     computed: {
     },
 };
</script>
