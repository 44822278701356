<template>
    <div>
        <div class="mb-2 hide-loading" id="error_div">
        </div>
        <div v-show="hasCompanySelectAuth(roleId)" class="row mb-3 label-color">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label>会社名</label>
                <CompanyList
                    ref="companyList"
                    elm-id="company-select-box"
                    :store-id="storeId"
                    :include-all="true"
                    :show-with-code="hasCompanySelectAuth(roleId)"
                    @data-loaded="handleDataLoaded"
                ></CompanyList>
            </div>
            <div v-if="hasRegisterAuth && hasCompanySelectAuth(roleId)" class="col-md-2 ms-auto mt-3 mt-md-0">
                <button
                    id="activate_button"
                    type="button"
                    class="btn btn-add-new icon-button ms-auto filter-license"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span v-if="isAuthRole">新規追加</span>
                    <span v-else>ライセンス登録</span>
                </button>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-status">使用状況</label>
                <div class="col-md-12" style="display: flex; gap: 10px;">
                    <button
                        v-if="isAuthRole"
                        type="button"
                        :class="[
                            'btn',
                            'filter-link',
                            status.unused ? 'filter-active' : '',
                        ]"
                        data-id="未"
                        @click="toggleStatus('unused')"
                    >
                        未
                    </button>
                    <button
                        type="button"
                        :class="[
                            'btn',
                            'filter-link',
                            status.planned ? 'filter-active' : '',
                        ]"
                        data-id="利用予定"
                        @click="toggleStatus('planned')"
                    >
                        利用予定
                    </button>
                    <button
                        type="button"
                        :class="[
                            'btn',
                            'filter-link',
                            status.inuse ? 'filter-active' : '',
                        ]"
                        data-id="利用中"
                        @click="toggleStatus('inuse')"
                    >
                        利用中
                    </button>
                    <button
                        type="button"
                        :class="[
                            'btn',
                            'filter-link',
                            status.expired ? 'filter-active' : '',
                        ]"
                        data-id="有効期限切れ"
                        @click="toggleStatus('expired')"
                    >
                        有効期限切れ
                    </button>
                </div>
            </div>
            <div v-if="hasRegisterAuth && !hasCompanySelectAuth(roleId)" class="col-sm-2 col-md-2 ms-auto">
                <button
                    id="activate_button"
                    type="button"
                    class="btn btn-add-new icon-button ms-auto filter-license"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span v-if="isAuthRole">新規追加</span>
                    <span v-else>ライセンス登録</span>
                </button>
            </div>
        </div>

        <div class="row btn-spacer"></div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    class="btn mx-1 btn-reset flex-fill"
                    @click="onClickClearSearchParams"
                >
		    検索条件をリセット
	        </button>
	        <button
                    :disabled="isLoading"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickSearch"
                >
                    <img src="/images/search.svg" width="20px" />
                    この条件で検索
	        </button>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col ms-auto text-end">
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="download-csv"
                    style="cursor:pointer;"
                    @click="onClickExportLicense"
                >
                    <img src="/images/download.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="download_data">ダウンロード</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
 import { hasCompanySelectAuth } from '@/composables/common.js';
 import { useCommonSearchParamState } from '@/stores/commonSearchParam';
 import CompanyList from '@/components/CompanyList.vue';
 export default {
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
     },
     props: {
         roleId: {type: Number, required: true},
     },
     computed: {
         isLoading() {
             // if (this.params.isLoading) return true;
             return false;
         },
         hasRegisterAuth() {
             return this.roleId < 4;
         },
         isAuthRole() {
             return this.roleId == 2;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             // params: useCommonSearchParamState('search-list'),
             companycode: '',
             status: {
                 unused: false,
                 planned: false,
                 inuse: true,
                 expired: false
             },
             initialized: false,
             listComponentStates: {company: true},
         };
     },
     mounted: async function() {
         initialize();
     },
     methods: {
         onClickSearch(event) {
             event.preventDefault();
             reloadTable();
         },
         onClickExportLicense(event) {
             downloadClick();
         },
         onClickRegister(event) {
             if (this.roleId == 2) {
                 licenseAdd();                
             }else{
                 licenseRegistration();
             }
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.companycode = '';
             this.status = {
                 unused: false,
                 planned: false,
                 inuse: true,
                 expired: false
             };
         },
         toggleStatus(value) {
             if (value == "unused") {
                 this.status.unused ? this.status.unused=false : this.status.unused=true;
             } else if (value == "planned"){
                 this.status.planned ? this.status.planned=false : this.status.planned=true;
             } else if (value == "inuse"){
                 this.status.inuse ? this.status.inuse=false : this.status.inuse=true;
             } else if (value == "expired"){
                 this.status.expired ? this.status.expired=false : this.status.expired=true;
             }
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 reloadTable();
                 this.initialized = true;
             }
         },
     },
     watch: {
     },
 }
</script>
