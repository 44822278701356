<template>
    <div>
        <div v-show="hasCompanySelectAuth(roleId)" class="row label-color mb-3">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label>会社名</label>
                <CompanyList
                    ref="companyList"
                    elm-id="select-company"
                    :store-id="storeId"
                    :include-all="true"
                    :show-with-code="hasCompanySelectAuth(roleId)"
                    @data-loaded="handleDataLoaded"
                ></CompanyList>
            </div>
            <div v-if="hasRegisterAuth && hasCompanySelectAuth(roleId)" class="col-md-2 ms-auto mt-3 mt-md-0">
                <button
                    type="button"
                    class="btn btn-add-new icon-button ms-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#locationModal"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span>拠点の新規追加</span>
                </button>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-4 col-md-3 label-color">
                <label for="select-location">拠点名</label>
                <div class="col-md-12">
                    <input
                        v-model="location"
                        @keypress.enter.prevent
                        id="select-location"
                        name="select-location"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        placeholder="">
                </div>
            </div>

            <div class="col-sm-4 col-md-3 label-color">
                <label for="select-rep_name_lable">代表者</label>
                <div class="col-md-12">
                    <input
                        v-model="repname"
                        @keypress.enter.prevent
                        id="select-rep_name"
                        name="select-rep_name"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        placeholder="">
                </div>
            </div>

            <div v-if="hasRegisterAuth && !hasCompanySelectAuth(roleId)" class="col-md-2 ms-auto mt-3 mt-md-0">
                <button
                    type="button"
                    class="btn btn-add-new icon-button ms-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#locationModal"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span>拠点の新規追加</span>
                </button>
            </div>

        </div>

        <div class="row btn-spacer"></div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    class="btn mx-1 btn-reset flex-fill"
                    @click="onClickClearSearchParams"
                >
		    検索条件をリセット
	        </button>
	        <button
                    :disabled="isLoading"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickSearch"
                >
                    <img src="/images/search.svg" width="20px" />
                    この条件で検索
	        </button>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col ms-auto text-end">
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="import_location_csv"
                    style="cursor:pointer;"
                    @click="onClickImportLocation"
                >
                    <img src="/images/upload.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="download_data">拠点の一括登録</span>
                </button>
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="export_location_csv"
                    style="cursor:pointer;"
                    @click="onClickExportLocation"
                >
                    <img src="/images/download.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="download_data">ダウンロード</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { hasCompanySelectAuth } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { useCommonSearchParamState } from '@/stores/commonSearchParam';
 import { useCommonStore } from '@/stores/common';
 import CompanyList from '@/components/CompanyList.vue';
 import LocationList from '@/components/LocationList.vue';

 export default {
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
     },
     props: {
         roleId: {type: Number, required: true},
     },
     computed: {
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
         hasRegisterAuth() {
             return this.roleId != 4;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             location: '',
             repname: '',
             initialized: false,
             listComponentStates: {company: true},
         };
     },
     mounted: async function() {
     },
     methods: {
         onClickSearch(event) {
             event.preventDefault();
             reloadTable();
         },
         onClickImportLocation(event) {
            LocationImportFormShow();
         },
         onClickExportLocation(event) {
             handleClickExportLocation();
         },
         onClickRegister(event) {
             locationRegistration();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.location = '';
             this.repname = '';
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 reloadTable();
                 this.initialized = true;
             }
         },
     },
     watch: {
     },
 }
</script>
