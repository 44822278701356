<template>
    <div class="info-icon-area">
        <img class="info-icon" src="/images/info1.svg" @click="open">
        <Modal
            v-model="showModal"
            modal-class="new-modal"
            wrapper-class="new-modal-wrapper message-popup"
            :enableClose="true"
        >
            <div class="new-modal-content">
                <div class="mb-3">
                    <div class="notification-message">
                        {{ $_cstr('STRID_MESSAGE_NOTIFICATION_UNAVAILABLE') }}
                    </div>
                    <ul class="notification-cond-list">
                        <li>{{ $_cstr('STRID_MESSAGE_NOTIFICATION_COND_1') }}</li>
                        <li>{{ $_cstr('STRID_MESSAGE_NOTIFICATION_COND_2') }}</li>
                    </ul>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
 export default {
     props: {
     },
     computed: {
     },
     data: function() {
         return {
             showModal: false,
         };
     },
     mounted: async function() {
         this.$root.$notificationUnavailableInfo = this;
     },
     methods: {
         open() {
             this.showModal = true;
         },
         onClose() {
             this.showModal = false;
         },
     },
 }
</script>
