<template>
    <div class="send-message">
        <LoadingOverlay :isVisible="isLoading" />
        <MessageSendConfirmationModal
            ref="confirmModal"
            :confirmer-info="userInfo"
            :message="message"
            @execSend="handleExecSend"
        ></MessageSendConfirmationModal>
        <div class="row mb-3">
            <div class="col"></div>
            <div class="col-sm-12 col-md-8 col-lg-6 input-count-wrapper">
                <textarea
                    id="message"
                    rows=2
                    v-model="message"
                    type="text"
                    class="form-control"
                    name="message"
                    placeholder=""
                    :maxlength="messageBodyMaxSize"
                    @keypress.enter.prevent
                    @paste="handlePaste"
                />
                <span class="char-count">{{ message.length }}/{{ messageBodyMaxSize }}</span>
            </div>
            <div class="col"></div>
        </div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    :disabled="isSendDisabled"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickConfirmSending"
                >
                    {{ $_cstr('STRID_BUTTON_CONFIRM_SEND_MESSAGE') }}
	        </button>
            </div>
            <div class="col"></div>
        </div>

        <div class="row cond-result-spacer"></div>

        <div class="new-table-container">
            <div class="new-table-wrapper main-left"></div>

            <div class="new-table-wrapper main">


                <div class="table-title-area custom-margin">
                    <div class="item custom-margin">
                        {{ $_cstr('STRID_LABEL_DRIVER') }}
                    </div>
                    <div class="item">
                        <NotificationUnavailableInfo
                            ref="messagePopup"
                        ></NotificationUnavailableInfo>
                    </div>
                </div>
                <div class="row label-color mb-3">
                    <div :class="searchParamWidthClasses">
                        <label for="base-select-location">
                            {{ $_cstr('STRID_SEARCH_ITEM_LOCATION') }}
                        </label>
                        <MyLocationList
                            ref="myLocationList"
                            elm-id="base-select-location"
                            :store-id="base.storeId"
                            :include-all="true"
                            @data-loaded="handleBaseDataLoaded"
                            @handlerChange="onChangeBaseSearchParam"
                        ></MyLocationList>
                    </div>
                    <div :class="searchParamWidthClasses">
                        <label for="baseName">
                            {{ $_cstr('STRID_SEARCH_ITEM_DRIVER_NAME') }}
                        </label>
                        <input
                            id="baseName"
                            v-model="base.name"
                            @keypress.enter.prevent
                            @input="onChangeBaseSearchParam"
                            type="text"
                            class="form-control"
                            name="baseName"
                            placeholder=""
                        >
                    </div>
                </div>
                <table class="new-table head">
                    <thead>
                        <tr>
                            <th class="driver-id">
                                {{ $_cstr('STRID_TABLE_HEAD_LOCATION_NAME') }}
                            </th>
                            <th class="driver-name">
                                {{ $_cstr('STRID_SEND_MESSAGE_TABLE_HEAD_DRIVER_NAME') }}
                            </th>
                            <th class="action">
                                <input
                                    type="checkbox"
                                    ref="baseHeaderCheckbox"
                                    @change="toggleBaseAll($event)"
                                    :indeterminate="baseHeaderIndeterminate"
                                    :checked="baseHeaderChecked"
                                    :disabled="baseHeaderCheckboxDisabled"
                                />
                            </th>
                        </tr>
                    </thead>
                </table>
                <div class="new-table-body-wrapper">
                    <table class="new-table body">
                        <tbody>
                            <tr v-for="(driver, index) in base.filtered" :key="driver.id">
                                <td class="driver-id">{{ driver.location?.name }}</td>
                                <td class="driver-name">{{ driver.driver_name }}</td>
                                <td class="action">
                                    <div v-if="!driver.isNotificationEnabled">
                                        {{ $_cstr('STRID_LABEL_NO_FCM_TOKEN') }}
                                    </div>
                                    <input v-else
                                        type="checkbox"
                                        :checked="isCheckedBaseItem(driver.id)"
                                        @change="toggleCheckBaseIds(driver.id)"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="new-table-wrapper main-right"></div>
            <div class="new-table-wrapper center move-button-area">
                <button class="btn btn-outline-primary btn-move"
                        @click="handleClickAdd"
                        :disabled="isAddDisabled"
                >
                    <i class="fas fa-angles-right"></i>
                </button>
                <button class="btn btn-outline-primary btn-move"
                        @click="handleClickRemove"
                        :disabled="isRemoveDisabled"
                >
                    <i class="fas fa-angles-left"></i>
                </button>
            </div>
            <div class="new-table-wrapper sub-left"></div>
            <div class="new-table-wrapper sub">

                <div class="table-title-area">
                    <div class="item">
                        {{ $_cstr('STRID_LABEL_TARGET_SEND_MESSAGE') }}
                    </div>
                </div>
                <table class="new-table head">
                    <thead>
                        <tr>
                            <th class="driver-id">
                                {{ $_cstr('STRID_TABLE_HEAD_LOCATION_NAME') }}
                            </th>
                            <th class="driver-name">
                                {{ $_cstr('STRID_SEND_MESSAGE_TABLE_HEAD_DRIVER_NAME') }}
                            </th>
                            <th class="action">
                                <input
                                    type="checkbox"
                                    ref="targetHeaderCheckbox"
                                    @change="toggleTargetAll($event)"
                                    :indeterminate="targetHeaderIndeterminate"
                                    :checked="targetHeaderChecked"
                                    :disabled="targetHeaderCheckboxDisabled"
                                />
                            </th>
                        </tr>
                    </thead>
                </table>
                <div class="new-table-body-wrapper">
                    <table class="new-table body">
                        <tbody>
                            <tr v-for="(driver, index) in target.filtered" :key="index">
                                <td class="driver-id">{{ driver.location?.name }}</td>
                                <td class="driver-name">{{ driver.driver_name }}</td>
                                <td class="action">
                                    <input
                                        type="checkbox"
                                        :checked="isCheckedTargetItem(driver.id)"
                                        @change="toggleCheckTargetIds(driver.id)"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="new-table-wrapper sub-right"></div>
        </div>

    </div>
</template>

<script>
 import MyLocationList from '@/components/MyLocationList.vue';
 import axios from '@/composables/axios.js';
 import BaseToastComponent from '@/components/common/BaseToastComponent.vue';
 import { isStringEmpty, isListEmpty, checkAlpitResponse, hasCompanySelectAuth } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { popupSuccess, popupInfo, confirm } from '@/composables/popup.js';
 import ToggleSwitch from '@/components/parts/ToggleSwitch.vue';
 import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
 import MessageSendConfirmationModal from '@/components/common/MessageSendConfirmationModal.vue';
 import NotificationUnavailableInfo from '@/components/common/NotificationUnavailableInfo.vue';

 export default {
     mixins: [
         BaseToastComponent,
     ],
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         MyLocationList,
         ToggleSwitch,
         LoadingOverlay,
         MessageSendConfirmationModal,
         NotificationUnavailableInfo,
     },
     props: {
         userInfo: {type: Object, required: true},
         roleId: {type: Number, required: true},
         messageBodyMaxSize: {type: Number, default: 40},
     },
     computed: {
         isLoading() {
             if (this.base.params.isLoading) return true;
             if (this.target.params.isLoading) return true;
             if (this.isDriverLoading) return true;
             return false;
         },
         isAddDisabled() {
             return this.getIdsToAdd().length <= 0;
         },
         isRemoveDisabled() {
             return this.getIdsToRemove().length <= 0;
         },
         isSendDisabled() {
             if (this.isLoading) return true;
             if (this.message.trim().length <= 0) return true;
             if (this.target.drivers.length <= 0) return true;
             return false;
         },
         isDirty() {
             if (this.message.length > 0) return true;
             if (this.target.drivers.length > 0) return true;
             return false;
         },
         baseHeaderChecked() {
             const checkedState = this.getBaseCheckedState();
             return checkedState.checked;
         },
         baseHeaderIndeterminate() {
             const checkedState = this.getBaseCheckedState();
             return checkedState.indeterminate;
         },
         targetHeaderChecked() {
             const checkedState = this.getTargetCheckedState();
             return checkedState.checked;
         },
         targetHeaderIndeterminate() {
             const checkedState = this.getTargetCheckedState();
             return checkedState.indeterminate;
         },
         baseHeaderCheckboxDisabled() {
             return this.getUnassignedValidDispDrivers().length <= 0;
         },
         targetHeaderCheckboxDisabled() {
             return this.target.filtered.length <= 0;
         },
         searchParamWidthClasses() {
             return [
                 'col-12',
                 'col-xxl-6',
                 /* 'col-sm-12',
                  * 'col-md-12',
                  * 'col-lg-12',
                  * 'col-xl-10',
                  * 'col-xxl-9',
                  * 'col-xxxl-8',
                  * 'col-xxxxl-7',
                  * 'col-xxxxxl-6', */
             ];
         },
     },
     data: function() {
         return {
             base: {
                 storeId: 'base-search-list',
                 params: createCompanyLocationParamState('base-search-list'),
                 listComponentStates: {company: true, location: true},
                 name: '',
                 drivers: [],
                 filtered: [],
                 checkedIds: [],
             },
             target: {
                 storeId: 'target-search-list',
                 params: createCompanyLocationParamState('target-search-list'),
                 name: '',
                 drivers: [],
                 filtered: [],
                 checkedIds: [],
             },
             message: '',
             initialized: false,
             isDriverLoading: false,
         };
     },
     mounted: async function() {
         this.$root.$sendMessagePage = this; // XXX
         this.initialize();
         this.bindPageBeforeUnloadEvent();
     },
     beforeUnmount() {
         this.unbindPageBeforeUnloadEvent();
     },
     methods: {
         bindPageBeforeUnloadEvent() {
             document.querySelectorAll('.confirm_link').forEach(link => link.addEventListener('click', this.handleLinkClick));
         },
         unbindPageBeforeUnloadEvent() {
             document.querySelectorAll('.confirm_link').forEach(link => link.removeEventListener('click', this.handleLinkClick));
         },
         async handleLinkClick(event) {
             if (!this.isDirty) return;

             const href = event.currentTarget.href;
             event.preventDefault();
             const result = await confirm(this.$_cstr('STRID_HTML_CONFIRM_TRANSITION'),
                                          this.$_cstr('STRID_BUTTON_LABEL_YES'),
                                          this.$_cstr('STRID_BUTTON_LABEL_NO'));
             if (result.isConfirmed) {
                 window.location.href = href;
             }
         },
         initialize() {
             this.message = '',

             this.base.name = '',
             this.base.drivers = [];
             this.base.filtered = [];
             this.base.checkedIds = [];

             this.clearTargets();
         },
         clearFilteredBaseInfo() {
             this.$refs.myLocationList.clearSelection();
             this.base.name = '';
             this.base.filtered = this.base.drivers;
             this.base.checkedIds = [];
         },
         clearTargets() {
             this.target.name = '',
             this.target.drivers = [];
             this.target.filtered = [];
             this.target.checkedIds = [];
         },
         toggleCheckBaseIds(id) {
             if (this.base.checkedIds.includes(id)) {
                 this.base.checkedIds = this.base.checkedIds.filter(checkedId => checkedId !== id);
             } else {
                 this.base.checkedIds.push(id);
             }
         },
         toggleCheckTargetIds(id) {
             if (this.target.checkedIds.includes(id)) {
                 this.target.checkedIds = this.target.checkedIds.filter(checkedId => checkedId !== id);
             } else {
                 this.target.checkedIds.push(id);
             }
         },
         isChecked(id,list) {
             return list.includes(id);
         },
         isCheckedBaseItem(id) {
             return this.isChecked(id,this.base.checkedIds);
         },
         isCheckedTargetItem(id) {
             return this.isChecked(id,this.target.checkedIds);
         },
         getIdsToAdd() {
             const dispIds = this.base.filtered.map(d => d.id);
             const toAddDriverIds = this.base.checkedIds.filter(id => dispIds.includes(id));
             return toAddDriverIds;
         },
         getIdsToRemove() {
             const dispIds = this.target.filtered.map(d => d.id);
             const toRemoveDriverIds = this.target.checkedIds.filter(id => dispIds.includes(id));
             return toRemoveDriverIds;
         },
         handleClickAdd() {
             const toAddDriverIds = this.getIdsToAdd();
             const toAddDrivers = this.base.filtered.filter(d => toAddDriverIds.includes(d.id));
             if (toAddDrivers.length <= 0) return;

             const toAddIds = toAddDrivers.map(d => d.id);
             this.target.drivers.push(...toAddDrivers);
             this.base.checkedIds = this.base.checkedIds.filter(id => !toAddIds.includes(id));
             this.updateFilteredBaseList();
             this.updateBaseHeaderCheckboxState();
             this.updateFilteredTargetList();
             this.updateTargetHeaderCheckboxState();
         },
         handleClickRemove() {
             const toRemoveDriverIds = this.getIdsToRemove();
             const toRemoveDrivers = this.target.drivers.filter(d => toRemoveDriverIds.includes(d.id));
             if (toRemoveDrivers.length <= 0) return;

             const toRemoveIds = toRemoveDrivers.map(d => d.id);
             this.target.drivers = this.target.drivers.filter(d => !toRemoveIds.includes(d.id));
             this.target.checkedIds = this.target.checkedIds.filter(id => !toRemoveIds.includes(id));
             this.updateFilteredBaseList();
             this.updateBaseHeaderCheckboxState();
             this.updateFilteredTargetList();
             this.updateTargetHeaderCheckboxState();
         },
         onClickConfirmSending() {
             this.$refs.confirmModal.open();
         },
         async updateDriverList() {
             this.isDriverLoading = true;
             try {
                 const response = await axios.get('/sendmessage/drivers',{params:{
                     lid: this.base.params.locationSelected,
                     name: this.name,
                 }});
                 this.base.drivers = response.data.drivers;
                 this.updateFilteredBaseList();
                 this.applyAllCheckOnToDrivers();
                 this.updateBaseHeaderCheckboxState();
             } catch(error) {
                 console.error(error);
             } finally {
                 this.isDriverLoading = false;
             }
         },
         updateFilteredBaseList() {
             this.base.filtered = this.getUnassignedDrivers();
             if (!isStringEmpty(this.base.params.locationSelected)) {
                 this.base.filtered = this.base.filtered.filter(d => d.location_id == this.base.params.locationSelected);
             }
             if (!isStringEmpty(this.base.name)) {
                 this.base.filtered = this.base.filtered.filter(d => {
                     const name = d.driver_name.normalize("NFKC").toLowerCase();
                     const search = this.base.name.normalize("NFKC").toLowerCase();
                     return name.includes(search);
                 });
             }
         },
         updateFilteredTargetList() {
             this.target.filtered = this.target.drivers;
             if (!isStringEmpty(this.target.params.locationSelected)) {
                 this.target.filtered = this.target.filtered.filter(d => d.location_id == this.target.params.locationSelected);
             }
             if (!isStringEmpty(this.target.name)) {
                 this.target.filtered = this.target.filtered.filter(d => {
                     const name = d.driver_name.normalize("NFKC").toLowerCase();
                     const search = this.target.name.normalize("NFKC").toLowerCase();
                     return name.includes(search);
                 });
             }
         },
         handleBaseDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.base.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.base.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 this.updateDriverList();
                 this.initialized = true;
             }
         },
         handlePaste(event) {
             const clipboardData = event.clipboardData || window.clipboardData;
             const pastedText = clipboardData.getData("Text");
             const cleanedText = pastedText.replace(/\r?\n|\r/g, ""); // 改行を削除
             event.preventDefault(); // デフォルトのペースト動作をキャンセル
             document.execCommand("insertText", false, cleanedText); // 改行を削除したテキストを挿入
         },
         getValidDrivers() {
             return this.base.filtered.filter(d => d.isNotificationEnabled);
         },
         applyAllCheckOnToDrivers() {
             const ids = this.getValidDrivers().map(d => d.id);
             this.base.checkedIds = [];
             this.base.checkedIds.push(...ids);
         },
         isEnableDriverToAdd(driver,addedIds) {
             if (!driver.isNotificationEnabled) return false;
             if (addedIds.includes(driver.id)) return false;
             return true;
         },
         isEnableDriverToDisp(driver,addedIds) {
             if (addedIds.includes(driver.id)) return false;
             return true;
         },
         getUnassignedDrivers() {
             const addedIds = this.target.drivers.map(function(t){return t.id;});
             const filtered = this.base.drivers.filter(function(d){
                 return this.isEnableDriverToDisp(d,addedIds);
             }.bind(this));
             return filtered;
         },
         getUnassignedValidDispDrivers() {
             const addedIds = this.target.drivers.map(function(t){return t.id;});
             const filtered = this.base.filtered.filter(function(d){
                 return this.isEnableDriverToAdd(d,addedIds);
             }.bind(this));
             return filtered;
         },
         async handleExecSend() {
             const postData = {
                 ids: this.target.drivers.map(d => d.id),
                 body: this.message,
             };
             try {
                 const response = await axios.post('/sendmessage', postData);
                 popupSuccess(this.$_cstr('STRID_MESSAGE_COMPLETE_SEND_MESSAGE'));

                 this.message = '';
                 this.clearFilteredBaseInfo();
                 this.applyAllCheckOnToDrivers();
                 this.clearTargets();
                 this.updateFilteredBaseList();
                 this.updateBaseHeaderCheckboxState();
                 this.updateFilteredTargetList();
                 this.updateTargetHeaderCheckboxState();

                 //this.toastMessage('info', '送信しました',true,3000);
             } catch (error) {
                 console.error(error);
                 popupInfo(this.$_cstr('STRID_MESSAGE_ERROR_SEND_MESSAGE'));
                 /* const statusCode = error?.response?.status;
                  * if (statusCode == 422) {
                  *     const messages = error?.response.data.errors;
                  *     const messageList = Object.values(messages).flat();
                  *     if (messageList.length > 0) {
                  *         this.toastMessage('error', messageList.join('<br>'), true);
                  *     }
                  * } else {
                  *     //const errorMessage = error?.response?.data?.message;
                  *     //if (!isStringEmpty(errorMessage)) this.toastMessage('error', errorMessage, true);
                  *     this.toastMessage('error', 'エラーが発生しました。', true);
                  * } */
             }
         },
         onChangeBaseSearchParam(event) {
             this.updateFilteredBaseList();
             this.updateBaseHeaderCheckboxState();
         },
         onChangeTargetSearchParam(event) {
             this.updateFilteredTargetList();
             this.updateTargetHeaderCheckboxState();
         },
         toggleBaseAll($event) {
             const isChecked = event.target.checked;
             const dispDriverIds = this.getUnassignedValidDispDrivers().map(d => d.id);
             this.base.checkedIds = this.base.checkedIds.filter(id => !dispDriverIds.includes(id));
             if (isChecked) this.base.checkedIds.push(...dispDriverIds);
         },
         toggleTargetAll($event) {
             const isChecked = event.target.checked;
             const dispDriverIds = this.target.filtered.map(d => d.id);
             this.target.checkedIds = this.target.checkedIds.filter(id => !dispDriverIds.includes(id));
             if (isChecked) this.target.checkedIds.push(...dispDriverIds);
         },
         getCheckedState(driverIds,checkedIds) {
             const dispDriverIdSet = new Set(driverIds);
             const checkedIdSet = new Set(checkedIds);
             const allChecked  = [...dispDriverIdSet].every(item => checkedIdSet.has(item));
             const noneChecked = [...dispDriverIdSet].every(item => !checkedIdSet.has(item));
             if (allChecked) {
                 return {checked: true, indeterminate: false};
             } else if (noneChecked) {
                 return {checked: false, indeterminate: false};
             } else {
                 return {checked: false, indeterminate: true};
             }
         },
         getBaseCheckedState() {
             return this.getCheckedState(this.getUnassignedValidDispDrivers().map(d => d.id),this.base.checkedIds);
         },
         getTargetCheckedState() {
             return this.getCheckedState(this.target.filtered.map(d => d.id),this.target.checkedIds);
         },
         updateBaseHeaderCheckboxState() {
             const checkedState = this.getBaseCheckedState();
             this.$refs.baseHeaderCheckbox.checked = checkedState.checked;
             this.$refs.baseHeaderCheckbox.indeterminate = checkedState.indeterminate;
         },
         updateTargetHeaderCheckboxState() {
             const checkedState = this.getTargetCheckedState();
             this.$refs.targetHeaderCheckbox.checked = checkedState.checked;
             this.$refs.targetHeaderCheckbox.indeterminate = checkedState.indeterminate;
         },
         handleOpenMessagePopup() {
             this.$refs.messagePopup.open('test<br>test');
         },
     },
     watch: {
     },
 }
</script>
