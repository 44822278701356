import Swal from 'sweetalert2';
import { isStringEmpty } from './common.js';

const ICON_FILE_INFO = 'info1.svg';
const ICON_FILE_SUCCESS = 'success.svg';
const makeIconImgTag = iconfile => `<img src="/images/${iconfile}" width="35px">`;
const makeSwalBaseParams = () => {
    return {
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-info',
        },
        showClass: {
            popup: 'fade-in',
            backdrop: 'swal2-backdrop-show',
        },
        hideClass:{
            popup: 'fade-out',
            backdrop: 'swal2-backdrop-hide',
        },
    };
};

export function popup(str,iconfile){
    const iconHtml = makeIconImgTag(iconfile);
    return Swal.fire({
        iconHtml: iconHtml,
        text: str,
        ...makeSwalBaseParams(),
    });
}
export function popupInfo(str){
    return popup(str,ICON_FILE_INFO);
}
export function popupSuccess(str){
    return popup(str,ICON_FILE_SUCCESS);
}
export async function confirm(html, confirmButtonText = null, cancelButtonText = null){
    const params = {
        iconHtml: makeIconImgTag(ICON_FILE_INFO),
        html: html,
        showCancelButton: true,
        reverseButtons: true,
    };

    if (!isStringEmpty(confirmButtonText)) params['confirmButtonText'] = confirmButtonText;
    if (!isStringEmpty(cancelButtonText)) params['cancelButtonText'] = cancelButtonText;
    return Swal.fire({
        ...params,
        ...makeSwalBaseParams(),
    });
}
