<template>
    <div>
        <div class="margin">
            <div class="" id="error_div">
            </div>
            <div id="success_message"></div>

            <div class="row label-color mb-3">
                <div v-show="hasCompanySelectAuth(roleId)" class="col-sm-6 col-md-4 col-lg-3">
                    <label for="select-company">会社名</label>
                    <CompanyList
                        ref="companyList"
                        elm-id="select-company"
                        :store-id="storeId"
                        :include-all="true"
                        :show-with-code="hasCompanySelectAuth(roleId)"
                        @data-loaded="handleDataLoaded"
                    ></CompanyList>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <label for="select-location">拠点名</label>
                    <div class="col-md-12">
                        <LocationList
                            ref="locationList"
                            elm-id="select-location"
                            :store-id="storeId"
                            :include-all="true"
                            @data-loaded="handleDataLoaded"
                        ></LocationList>
                    </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-start-date">From</label>
                    <div>
                        <span class="datepicker-toggle" id="datepicker-toggle-start">
                            <span class="dropdown-toggle-button"></span>
                            <input
                                v-model="dateFrom"
                                ref="dateFrom"
                                :min="minEdgeOfDateString"
                                :max="maxEdgeOfDateString"
                                @focus="onFocusDateFrom"
                                @focusout="onFocusOutDateFrom"
                                @keydown="onKeyDownDateFrom"
                                type="date"
                                data-provide="datepicker"
                                data-date-format="yyyy-mm-dd"
                                id="select-start-date"
                                class="form-control"
                                placeholder='年 / 月 / 日'
                                style="cursor:context-menu  !important;">
                        </span>
                    </div>
                </div>

                <div class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-end-date">To</label>
                    <div>
                        <span class="datepicker-toggle" id="datepicker-toggle-end">
                            <span class="datepicker-toggle-button"></span>
                            <input
                                v-model="dateTo"
                                ref="dateTo"
                                :min="minEdgeOfDateString"
                                :max="maxEdgeOfDateString"
                                @focus="onFocusDateTo"
                                @focusout="onFocusOutDateTo"
                                @keydown="onKeyDownDateTo"
                                type="date"
                                class="form-control"
                                data-provide="datepicker"
                                data-date-format="yyyy-mm-dd"
                                id="select-end-date"
                                placeholder='年 / 月 / 日'
                                style="cursor:context-menu  !important;">
                        </span>
                    </div>
                </div>
            </div>

            <div class="row label-color mb-3">
                <input type='hidden' id="times" value="1">
                <input type='hidden' id='created-at' value="{{createdAt}}">
                <input type='hidden' id='user-name' value="{{userName}}">
                <input type='hidden' id='role-id' value="{{roleId}}">
                <input id="hid_com" type="hidden" name="hid_com" />

                <div class="col-sm-6 col-md-4 col-lg-3">
                    <label for="select-driname">運転者名</label>
                    <div class="col-md-12">
                        <input
                            v-model="driverName"
                            @keypress.enter.prevent
                            id="select-driname"
                            name="select-driname"
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            placeholder=""
                        >
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <label for="select-veicle-num">車両番号</label>
                    <div class="col-md-12">
                        <input
                            v-model="vehicleNum"
                            @keypress.enter.prevent
                            id="select-veiclenum"
                            name="select-veiclenum"
                            type="text"
                            class="form-control"
                            autocomplete="off"
                            placeholder=""
                        >
                    </div>
                </div>

                <div class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-date"></label>
                    <div class="col-md-12" style="display: flex; gap: 10px;">
                        <button 
                            name="filter-bar" 
                            type="button" 
                            :class="[
                                'btn', 
                                'filter-link',
                                { 'filter-active': isActiveYesterday }
                            ]" 
                            id="select-yesterday-warper"
                            @click="toggleDate('yesterday')">
                            <span class=" select_yesterday" id='select-yesterday'>昨日</span>
                        </button>
                        <button 
                            name="filter-bar" 
                            type="button"
                            :class="[
                                'btn',
                                'filter-link',
                                { 'filter-active': isActiveToday }
                            ]"
                            id="select-today-warper"
                            @click="toggleDate('today')">
                            <span class=" select_today" id="select-today">本日</span>
                        </button>
                    </div>
                </div>
            </div>

            <input type="hidden" id="cid">

            <div class="row btn-spacer"></div>

            <div class="row">
                <div class="col"></div>
                <div class="col-12 col-md-6 d-flex justify-content-center">
                    <button
                        class="btn mx-1 btn-reset flex-fill"
                        @click="onClickClearSearchParams"
                    >
                        検索条件をリセット
                    </button>

                    <button
                        :disabled="isLoading"
                        class="btn mx-1 btn-search flex-fill"
                        @click="onClickSearch"
                    >
                        <img src="/images/search.svg" width="20px" />
                        この条件で検索
                    </button>
                </div>
                <div class="col"></div>
            </div>
            <div class="row">
                <div class="col ms-auto text-end">
                    <button
                        :disabled="isLoading"
                        @click="onClickDownload"
                        type="button"
                        class="btn btn-light link-style-button"
                        id="download-csv"
                        style="cursor:pointer;"
                    >
                        <img src="/images/download.svg" class="calendar_div mx-1" width="20" height="20" />
                        <span class="select_notconfirm">ダウンロード</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { DateTime } from "luxon";
 import { makeStartOfToday, makeEndOfToday, makeStartOfYesterday, makeEndOfYesterday,
          minEdgeOfDate, maxEdgeOfDate, minEdgeOfDateString, maxEdgeOfDateString,
          makeMinEdgeOfSelectedDate, makeMaxEdgeOfSelectedDate, makeStartOfUnselectedDateFrom,
 } from '@/composables/datetime.js';
 import { isStringEmpty, hasCompanySelectAuth } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { useCommonSearchParamState } from '@/stores/commonSearchParam';
 import { useCommonStore } from '@/stores/common';
 import CompanyList from '@/components/CompanyList.vue';
 import LocationList from '@/components/LocationList.vue';
 import BaseDatepickerComponent from './BaseDatepickerComponent.vue';

 export default {
     extends: BaseDatepickerComponent,
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
     },
     props: {
         userName: {type: String, default: ''},
         roleId: {type: Number, required: true},
         createdAt: {type: String, default: ''},
     },
     computed: {
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             licenseTypeCount: 0,
             dateFrom: '',
             dateTo: '',
             driverName: '',
             vehicleNum: '',
             initialized: false,
             listComponentStates: {company: true, location: true},
             isActiveYesterday: false,
             isActiveToday: true,
             minEdgeOfDateString: null,
             maxEdgeOfDateString: null,
         };
     },
     mounted: async function() {
         this.initializePeriod();
     },
     methods: {
         makeDateTimeFromFormat(dateStr) {
             return DateTime.fromFormat(dateStr, 'yyyy-MM-dd HH:mm');
         },
         makeDatetimeValueFromFormat(dateStr) {
             const dt = this.makeDateTimeFromFormat(dateStr);
             return this.makeDatetimeValue(dt);
         },
         onClickSearch(event) {
             event.preventDefault();
             pullDataFromDriverVehicle();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.$refs.locationList.clearSelection();
             this.initializePeriod();
             this.driverName = '';
             this.vehicleNum = '';
             this.isActiveYesterday = false;
             this.isActiveToday = true;
         },
         onClickDownload(event) {
             handleClickDownloadXlsx(event);
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 pullDataFromDriverVehicle();
                 this.initialized = true;
             }
         },
         toggleDate(name) {
             switch (name) {
                case 'yesterday':
                     this.isActiveYesterday = !this.isActiveYesterday;
                     break;
                 case 'today':
                     this.isActiveToday = !this.isActiveToday;
                     break;
             }
             if (this.isActiveToday && !this.isActiveYesterday){
                 this.setTerm(makeStartOfToday(),
                              makeEndOfToday());
             } else if (this.isActiveToday && this.isActiveYesterday){
                 this.setTerm(makeStartOfYesterday(),
                              makeEndOfToday());
             } else if (!this.isActiveToday && this.isActiveYesterday){
                 this.setTerm(makeStartOfYesterday(),
                              makeEndOfYesterday());
             } else if (!this.isActiveToday && !this.isActiveYesterday){
                 this.setTerm(makeStartOfUnselectedDateFrom(makeStartOfToday()),
                              makeEndOfToday());
             }
             this.updateEdgeOfSelectedDate();
         },
         handleChangePeriod() {
             const dFrom = DateTime.fromISO(this.dateFrom);
             const dTo = DateTime.fromISO(this.dateTo);
             const yesterday = makeStartOfYesterday();
             const today = makeStartOfToday();
             this.isActiveYesterday = false;
             this.isActiveToday = false;

             // この組み合わせのみ。ずれたら外さないといけない。
             if (dFrom.equals(yesterday) && dTo.equals(yesterday)) {
                 this.isActiveYesterday = true;
             } else if (dFrom.equals(yesterday) && dTo.equals(today)) {
                 this.isActiveYesterday = true;
                 this.isActiveToday = true;
             } else if (dFrom.equals(today) && dTo.equals(today)) {
                 this.isActiveToday = true;
             }
         },
         handleChangeFrom() {
             this.handleChangePeriod();
         },
         handleChangeTo() {
             this.handleChangePeriod();
         },
     },
 }
</script>
