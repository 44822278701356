<template>
    <div>
        <div class="row label-color mb-3">
            <div v-show="hasCompanySelectAuth(roleId)" class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-company">会社名</label>
                <CompanyList
                    ref="companyList"
                    elm-id="select-company"
                    :store-id="storeId"
                    :include-all="true"
                    :show-with-code="hasCompanySelectAuth(roleId)"
                    @data-loaded="handleDataLoaded"
                ></CompanyList>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-location">拠点名</label>
                <LocationList
                    ref="locationList"
                    elm-id="select-location"
                    :store-id="storeId"
                    :include-all="true"
                    @data-loaded="handleDataLoaded"
                ></LocationList>
            </div>
            <div class="col-md-2 ms-auto mt-3 mt-md-0">
                <button
                    :disabled="isLoading"
                    type="button"
                    class="btn btn-add-new icon-button ms-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#userModal"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span>管理者の新規追加</span>
                </button>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-4 col-md-3 label-color">
                <label for="select-name">氏名</label>
                <div class="col-md-12">
                    <input
                        v-model="name"
                        @keypress.enter.prevent
                        id="select-name"
                        autocomplete="false"
                        type="text"
                        class="form-control mdb-autocomplete"
                        name="select-name"
                        placeholder=""
                    >
                </div>
            </div>

            <div class="col-sm-4 col-md-3 label-color">
                <label for="select-email">メールアドレス</label>
                <div class="col-md-12">
                    <input
                        v-model="email"
                        @keypress.enter.prevent
                        id="select-email"
                        autocomplete="false"
                        type="text"
                        class="form-control mdb-autocomplete"
                        name="select-email"
                        placeholder=""
                    >
                </div>
            </div>

            <div class="col-sm-4 col-md-3 label-color">
                <label for="select-level">権限</label>
                <div class="col-md-12" style="display: flex; gap: 10px;">
                    <div v-for="role in roles" :key="role.id">
                        <button
                            type="button"
                            :class="[
                                'btn',
                                'filter-link',
                                selectedRoles.indexOf(role.id) !== -1 ? 'filter-active' : '',
                            ]"
                            :data-id="role.id"
                            @click="toggleRole(role.id)"
                        >
                            {{ role.id == 2 ? 'アイリス' : role.name }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row btn-spacer"></div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    class="btn mx-1 btn-reset flex-fill"
                    @click="onClickClearSearchParams"
                >
		    検索条件をリセット
	        </button>
	        <button
                    :disabled="isLoading"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickSearch"
                >
                    <img src="/images/search.svg" width="20px" />
                    この条件で検索
	        </button>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col ms-auto text-end">
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="import_user_csv"
                    style="cursor:pointer;"
                    @click="onClickImportUser"
                >
                    <img src="/images/upload.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="download_data">管理者の一括登録</span>
                </button>
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="download-csv"
                    style="cursor:pointer;"
                    @click="onClickExportUser"
                >
                    <img src="/images/download.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="download_data">ダウンロード</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { hasCompanySelectAuth } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { useCommonSearchParamState } from '@/stores/commonSearchParam';
 import { useCommonStore } from '@/stores/common';
 import CompanyList from '@/components/CompanyList.vue';
 import LocationList from '@/components/LocationList.vue';

 export default {
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
     },
     props: {
         roleId: {type: Number, required: true},
     },
     computed: {
         isLoading() {
             if (this.isRoleLoading) return true;
             if (this.params.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             name: '',
             email: '',
             roles: [],
             selectedRoles: [3,4],
             isRoleLoading: false,
             initialized: false,
             listComponentStates: {company: true, location: true},
         };
     },
     mounted: async function() {
         await this.updateRolePulldown();
     },
     methods: {
         async onClickSearch(event) {
             event.preventDefault();
             await generateTable();
         },
         async updateRolePulldown() {
             this.isRoleLoading = true;
             const response = await axios.get('/user/rolelist');
             this.roles = response.data;
             this.isRoleLoading = false;
         },
         async onClickImportUser(event) {
            UserImportFormShow();
         },
         async onClickExportUser(event) {
             handleClickExportUser();
         },
         async onClickRegister() {
             userRegistration();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.$refs.locationList.clearSelection();
             this.name = '';
             this.email = '';
             this.selectedRoles = [3,4];
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 generateTable();
                 this.initialized = true;
             }
         },
         toggleRole(roleId) {
             const index = this.selectedRoles.indexOf(roleId);
             if (index !== -1) {
                 this.selectedRoles.splice(index, 1);
             } else {
                 this.selectedRoles.push(roleId);
             }
         }
     },
     watch: {
     },
 }
</script>
