<template>
    <select
        name="type-select-box"
        id="type-select-box"
        class="form-select"
        onchange="typeSelectOnChange(this)"
    >
        <option value="" selected></option>
        <option
            v-for="(license,index) in licenses"
            :key="license.type"
            :value="license.type"
        >
            {{ license.name }}
        </option>
    </select>
</template>

<script>
 import axios from '@/composables/axios.js';
 export default {
     components: {
     },
     emits: [
     ],
     props: {
     },
     computed: {
         isSelectDisabled() {
             if (this.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             isLoading: true,
             licenses: [],
         };
     },
     mounted: async function() {
         this.updateLicenseTypeList();
     },
     methods: {
         async updateLicenseTypeList() {
             this.isLoading = true;
             await this.getLicenseTypeList();
             this.isLoading = false;
         },
         async getLicenseTypeList() {
             try {
                 const response = await axios.get('/license/typelist');
                 this.licenses = response.data.data;
             } catch (error) {
                 console.error('Error fetching message:', error);
             }
         },
     },
     watch: {
     },
 }
</script>
