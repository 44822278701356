<template>
    <select
        class="form-select"
        ref="companySelect"
        v-model="params.companySelected"
        :id="elmId"
        :disabled="isSelectDisabled">
        <option v-if="hasBlankItem" value="" selected>{{ dispLabelAll }}</option>
	<option
            v-for="(company,index) in params.companies"
            :key="company.id"
            :value="company.id"
            :selected="index === 0 && !includeAll">
            {{ makeDispLabel(company) }}
        </option>
    </select>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 export default {
     components: {
     },
     emits: [
         'data-loaded',
     ],
     props: {
         elmId: {type: String, require: true},
         storeId: {type: String, require: true},
         includeAll: {type: Boolean, default: true},
         labelAll: {type: String, default: ''},
         showWithCode: {type: Boolean, default: false},
         fixSingleItem: {type: Boolean, default: true},
     },
     computed: {
         isSelectDisabled() {
             if (this.isDisable) return true;
             if (this.fixSingleItem && this.params.companies.length == 1) return true;
             return this.params.companies.length < 1;
         },
         hasBlankItem() {
             if (!this.includeAll) return false;
             if (!this.fixSingleItem && this.params.companies.length == 1) return true;
             return this.params.companies.length >= 2;
         },
     },
     data: function() {
         return {
             isDisable: true,
             dispLabelAll: '',
             params: createCompanyLocationParamState(this.storeId),
         };
     },
     mounted: async function() {
         this.dispLabelAll = this.$_cstr('STRID_SEARCH_ITEM_PULLDOWN_ALL');
         if (!isStringEmpty(this.labelAll)) this.this.dispLabelAll = this.labelAll;
         this.updateCompanyList();
     },
     methods: {
         async updateCompanyList() {
             this.$emit('data-loaded',{company: false});
             await this.getCompanyList();
             this.bindSumoSelect();
             this.isDisable = false;
             this.$emit('data-loaded',{company: true});
         },
         async getCompanyList() {
             try {
                 this.params.isCompanyLoading = true;
                 const response = await axios.get('/company/list');
                 this.params.companies = response.data;
                 if (this.fixSingleItem && this.params.companies.length == 1) {
                     this.params.companySelected = this.params.companies[0].id;
                     this.params.companyCodeSelected = this.params.companies[0].code;
                 }
             } catch (error) {
                 console.error('Error fetching message:', error);
             } finally {
                 this.params.isCompanyLoading = false;
             }
         },
         bindSumoSelect() {
             const $select = $(this.$refs.companySelect);
             this.$nextTick(function () {
                 $(function () {
                     $select.SumoSelect({
                         search: true,
                         searchText: '',
                         placeholder: '絞り込み',
                         noMatch: ''
                     });
                 });
             });
         },
         makeDispLabel(company) {
             const name = company.name;
             if (!this.showWithCode) return name;
             const code = company.code;
             return `${name}(${code})`;
         },
         clearSelection() {
             if (this.params.companies.length == 1) return;
             this.params.companySelected = '';
             this.params.companyCodeSelected = '';
             const $select = $(this.$refs.companySelect);
             if ($select[0].sumo) {
                 try {
                     $select[0].sumo.selectItem(0);
                 } catch(error) {
                     // カラッポの場合もあるのでエラー無視
                 }
             }
         },
     },
     watch: {
         'params.companySelected': {
             handler(newValue,oldValue) {
                 //console.info(`newValue: ${newValue}, oldValue: ${oldValue}`);
             },
             immediate: true,
         },
     },
 }
</script>
