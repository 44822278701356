<template>
    <div>
        <InstructionModal
            ref="instructionModal"
            :max-length="instructionMaxLength"
        ></InstructionModal>
        <div class="margin">
            <div class="" id="error_div">
            </div>
            <div id="success_message"></div>

            <div class="row label-color mb-3">
                <div v-show="hasCompanySelectAuth(roleId)" class="col-sm-6 col-md-4 col-lg-3">
                    <label>会社名</label>
                    <CompanyList
                        ref="companyList"
                        elm-id="select-company"
                        :store-id="storeId"
                        :include-all="true"
                        :show-with-code="hasCompanySelectAuth(roleId)"
                        @data-loaded="handleDataLoaded"
                    ></CompanyList>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <label for="select-location">拠点名</label>
                    <LocationList
                        ref="locationList"
                        elm-id="select-location"
                        :store-id="storeId"
                        :include-all="true"
                        @data-loaded="handleDataLoaded"
                    ></LocationList>
                </div>
                <div class="col-md-2 ms-auto mt-3 mt-md-0">
                    <button
                        v-if="showSettingInstructionButton"
                        :disabled="isLoading"
                        type="button"
                        class="btn btn-add-new icon-button ms-auto"
                        @click="onClickSettingInstruction"
                    >
                        <img src="/images/add.svg" width="20px" />
                        <span>{{ $_cstr('STRID_BUTTON_LABEL_SETTING_INSTRUCTION') }}</span>
                    </button>
                </div>
            </div>
            <div class="row label-color mb-3">
                <input type='hidden' id="times" value="1">
                <input type='hidden' id='created-at' :value="createdAt">
                <input type='hidden' id='user-name' :value="userName">
                <input type='hidden' id='role-id' :value="roleId">

                <div class="col-sm-6 col-md-4 col-lg-3">
                    <label for="select-name">測定者</label>
                    <input
                        v-model="name"
                        @keypress.enter.prevent
                        id="select-name"
                        autocomplete="off"
                        type="text"
                        class="form-control mdb-autocomplete"
                        name="select-name"
                        placeholder=""
                    >
                    <div id="autocompelete-box" class="col-md-12 active-border" hidden></div>
                    <input type="hidden" id="hd-position-relative" name="hd-position-relative">
                    <datalist id="select-name_d"/>
                </div>

                <div v-if="!isRetrieved" class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-start-date">From</label>
                    <div>
                        <span class="datepicker-toggle" id="datepicker-toggle-start">
                            <span class="dropdown-toggle-button"></span>
                            <input
                                v-model="dateFrom"
                                ref="dateFrom"
                                :min="minEdgeOfDateString"
                                :max="maxEdgeOfDateString"
                                @focus="onFocusDateFrom"
                                @focusout="onFocusOutDateFrom"
                                @keydown="onKeyDownDateFrom"
                                type="date"
                                data-provide="datepicker"
                                data-date-format="yyyy-mm-dd"
                                id="select-start-date"
                                class="form-control"
                                placeholder='年 / 月 / 日'
                                style="cursor:context-menu  !important;">
                        </span>
                    </div>
                </div>
                <div v-if="isRetrieved" class="col-sm-4 col-md-3 col-lg-2" id="date_text_from">
                    <label for="select-start-date">From</label>
                    <input
                        type="text"
                        id="select-start-date"
                        name="select-start-date"
                        class="form-control"
                        :value="dateFrom"
                        readonly
                    >
                </div>

                <div v-if="!isRetrieved" class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-end-date">To</label>
                    <div>
                        <span class="datepicker-toggle" id="datepicker-toggle-end">
                            <span class="datepicker-toggle-button"></span>
                            <input
                                v-model="dateTo"
                                ref="dateTo"
                                :min="minEdgeOfDateString"
                                :max="maxEdgeOfDateString"
                                @focus="onFocusDateTo"
                                @focusout="onFocusOutDateTo"
                                @keydown="onKeyDownDateTo"
                                type="date"
                                class="form-control"
                                data-provide="datepicker"
                                data-date-format="yyyy-mm-dd"
                                id="select-end-date"
                                placeholder='年 / 月 / 日'
                                style="cursor:context-menu  !important;">
                        </span>
                    </div>
                </div>
                <div v-if="isRetrieved" class="col-sm-4 col-md-3 col-lg-2" id="date_text_to">
                    <label for="select-end-date">To</label>
                    <input
                        type="text"
                        id="select-end-date"
                        name="select-end-date"
                        class="form-control"
                        :value="dateTo"
                        readonly
                    >
                </div>

                <div class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-date"></label>
                    <div class="col-md-12" style="display: flex; gap: 10px;">
                        <button 
                            name="filter-bar" 
                            type="button" 
                            :class="[
                                'btn', 
                                'filter-link',
                                { 'filter-active': isActiveYesterday }
                            ]" 
                            id="select-yesterday-warper"
                            @click="toggleDate('yesterday')">
                            <span class="select_yesterday" id='select-yesterday'>昨日</span>
                        </button>
                        <button 
                            name="filter-bar" 
                            type="button"
                            :class="[
                                'btn',
                                'filter-link',
                                { 'filter-active': isActiveToday }
                            ]"
                            id="select-today-warper"
                            @click="toggleDate('today')">
                            <span class="select_today" id="select-today">本日</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row label-color mb-3">
                <div class="col-sm-4 col-md-3 col-lg-2">
                    <label for="select-level">状況</label>
                    <div class="col-md-12" style="display: flex; gap: 10px;">
                        <button 
                            name="filter-bar"
                            type="button" 
                            :class="[
                                'btn',
                                'filter-link',
                                { 'filter-active': isActiveDrunk }
                            ]"
                            id="select-drunk-warper"
                            @click="toggleStatus('drunk')">
                            <span class=" select_drunk" id="select-drunk">酒気帯び</span>
                        </button>
                        <button 
                            name="filter-bar"
                            type="button" 
                            :class="[
                                'btn',
                                'filter-link',
                                { 'filter-active': isActiveNotconfirm }
                            ]"
                            id="select-notconfirm-warper"
                            @click="toggleStatus('notconfirm')">
                            <span class=" select_notconfirm" id="select-notconfirm">確認未実施</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row btn-spacer"></div>

            <div class="row">
                <div class="col"></div>
                <div class="col-12 col-md-6 d-flex justify-content-center">
	            <button
                        class="btn mx-1 btn-reset flex-fill"
                        @click="onClickClearSearchParams"
                    >
		        検索条件をリセット
	            </button>
	            <button
                        :disabled="isLoading"
                        class="btn mx-1 btn-search flex-fill"
                        @click="onClickSearch"
                    >
                        <img src="/images/search.svg" width="20px" />
                        この条件で検索
	            </button>
                </div>
                <div class="col"></div>
            </div>
            <div class="row">
                <div class="col ms-auto text-end">
                    <button
                        v-if="enableImportExport"
                        :disabled="isLoading"
                        @click="onClickExport"
                        class="btn btn-light link-style-button"
                        id="export-result"
                        style="cursor:pointer;"
                        disabled
                    >
                        エクスポート
                    </button>

                    <button
                        v-if="enableImportExport"
                        :disabled="isLoading"
                        @click="onClickImport"
                        class="btn btn-light link-style-button"
                        id="import-result"
                        disabled
                    >
                        インポート
                    </button>
                    <button
                        :disabled="isLoading"
                        @click="onClickDownload"
                        type="button"
                        class="btn btn-light link-style-button"
                        id="download-xlsx"
                        style="cursor:pointer;"
                    >
                        <img src="/images/download.svg" class="calendar_div mx-1" width="20" height="20" />
                        <span class="select_notconfirm">ダウンロード</span>
                    </button>
                </div>
            </div>
        </div>
        <input type="hidden" id="cid">
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { DateTime } from "luxon";
 import { makeStartOfToday, makeEndOfToday, makeStartOfYesterday, makeEndOfYesterday,
          minEdgeOfDate, maxEdgeOfDate, minEdgeOfDateString, maxEdgeOfDateString,
          makeMinEdgeOfSelectedDate, makeMaxEdgeOfSelectedDate, makeStartOfUnselectedDateFrom,
 } from '@/composables/datetime.js';
 import { isStringEmpty, hasCompanySelectAuth } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { useCommonSearchParamState } from '@/stores/commonSearchParam';
 import { useCommonStore } from '@/stores/common';
 import CompanyList from '@/components/CompanyList.vue';
 import LocationList from '@/components/LocationList.vue';
 import BaseDatepickerComponent from './BaseDatepickerComponent.vue';
 import InstructionModal from '@/components/common/InstructionModal.vue';

 export default {
     extends: BaseDatepickerComponent,
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
         InstructionModal,
     },
     props: {
         userName: {type: String, default: ''},
         roleId: {type: Number, required: true},
         createdAt: {type: String, default: ''},
         showSettingInstructionButton: {type: Boolean, default: false},
         instructionMaxLength: {type: Number, default: 20},
     },
     computed: {
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
         enableImportExport() {
             return this.roleId == 2;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             licenseTypeCount: 0,
             dateFrom: '',
             dateTo: '',
             name: '',
             initialized: false,
             listComponentStates: {company: true, location: true},
             isActiveYesterday: false,
             isActiveToday: true,
             isActiveDrunk: false,
             isActiveNotconfirm: false,
             isRetrieved: false,
             minEdgeOfDateString: null,
             maxEdgeOfDateString: null,
         };
     },
     mounted: async function() {
         this.initializePeriod();
         const params = new URLSearchParams(window.location.search);
         const dateFrom = params.get('dateFrom');
         const dateTo = params.get('dateTo');
         if (!isStringEmpty(dateFrom) || !isStringEmpty(dateTo)) {
             this.isRetrieved = true;
             this.isActiveToday = false;
             this.isActiveNotconfirm = true;
             this.dateFrom = this.makeDatetimeValueFromFormat(dateFrom);
             this.dateTo = this.makeDatetimeValueFromFormat(dateTo);
         }
         initialize();
     },
     methods: {
         makeDateTimeFromFormat(dateStr) {
             return DateTime.fromFormat(dateStr, 'yyyy-MM-dd HH:mm');
         },
         makeDatetimeValue(dt) { // Override
             return this.isRetrieved ?
                    dt.toFormat('yyyy/MM/dd HH:mm') :
                    dt.toFormat('yyyy-MM-dd');
         },
         makeDatetimeValueFromFormat(dateStr) {
             const dt = this.makeDateTimeFromFormat(dateStr);
             return this.makeDatetimeValue(dt);
         },
         onClickSearch(event) {
             event.preventDefault();
             pullDataFromDynamoDB();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.$refs.locationList.clearSelection();
             this.name = '';
             this.isActiveYesterday = false;
             this.isActiveToday = true;
             this.isActiveDrunk = false;
             this.isActiveNotconfirm = false;
             if (this.isRetrieved) {
                 this.isRetrieved = false;
                 this.$nextTick(function () {
                     $(function () {
                         initializeDatepicker();
                         this.initializePeriod();
                     }.bind(this));
                 }.bind(this));
             } else {
                 this.initializePeriod();
             }
         },
         onClickImport(event) {
             ImportFormShow(event);
         },
         onClickExport(event) {
             handleClickExportResult(event);
         },
         onClickDownload(event) {
             handleClickDownloadXLSX(event);
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (!allCompleted) return;

             if (this.isRetrieved) {
                 reloadTable();
             } else {
                 pullDataFromDynamoDB();
             }
             this.initialized = true;
         },
         toggleDate(name) {
             if (!this.isRetrieved) {
                 this._toggleDate(name);
                 return;
             }
             this.isRetrieved = false;
             this.$nextTick(function () {
                 $(function () {
                     initializeDatepicker();
                     this._toggleDate(name);
                 }.bind(this));
             }.bind(this));
         },
         _toggleDate(name) {
             switch (name) {
                case 'yesterday':
                     this.isActiveYesterday = !this.isActiveYesterday;
                     break;
                 case 'today':
                     this.isActiveToday = !this.isActiveToday;
                     break;
             }
             if (this.isActiveToday && !this.isActiveYesterday){
                 this.setTerm(makeStartOfToday(),
                              makeEndOfToday());
             } else if (this.isActiveToday && this.isActiveYesterday){
                 this.setTerm(makeStartOfYesterday(),
                              makeEndOfToday());
             } else if (!this.isActiveToday && this.isActiveYesterday){
                 this.setTerm(makeStartOfYesterday(),
                              makeEndOfYesterday());
             } else if (!this.isActiveToday && !this.isActiveYesterday){
                 this.setTerm(makeStartOfUnselectedDateFrom(makeStartOfToday()),
                              makeEndOfToday());
             }
             this.updateEdgeOfSelectedDate();
         },
         toggleStatus(name) {
             switch (name) {
                 case 'drunk':
                     this.isActiveDrunk = !this.isActiveDrunk;
                     break;
                 case 'notconfirm':
                     this.isActiveNotconfirm = !this.isActiveNotconfirm;
                     break;
             }
         },
         handleChangePeriod() {
             const dFrom = DateTime.fromISO(this.dateFrom);
             const dTo = DateTime.fromISO(this.dateTo);
             const yesterday = makeStartOfYesterday();
             const today = makeStartOfToday();
             this.isActiveYesterday = false;
             this.isActiveToday = false;

             // この組み合わせのみ。ずれたら外さないといけない。
             if (dFrom.equals(yesterday) && dTo.equals(yesterday)) {
                 this.isActiveYesterday = true;
             } else if (dFrom.equals(yesterday) && dTo.equals(today)) {
                 this.isActiveYesterday = true;
                 this.isActiveToday = true;
             } else if (dFrom.equals(today) && dTo.equals(today)) {
                 this.isActiveToday = true;
             }
         },
         handleChangeFrom() {
             this.handleChangePeriod();
         },
         handleChangeTo() {
             this.handleChangePeriod();
         },
         onClickSettingInstruction() {
             this.$refs.instructionModal.open();
         },
     },
 }
</script>
