<template>
    <a v-if="isFeatureEnabled" :href="href" :class="anchorClasses">
        <img :src="iconPathname" width="20px" />
        <span class="nav_name">{{ caption }} </span>
    </a>
</template>
<script>
 import axios from '@/composables/axios.js';
 export default {
     components: {
     },
     props: {
         itemName: {type: String, required: true},
         caption: {type: String, required: true},
         href: {type: String, required: true},
         active: {type: Boolean, default: false},
     },
     computed: {
         anchorClasses() {
             return [
                 'nav_link',
                 'confirm_link',
                 'link-click',
                 this.itemName,
                 this.active ? 'active' : '',
             ];
         },
         iconPathname() {
             return `/images/${this.itemName}.svg`;
         },
         isFeatureEnabled(){
             if (this.authInfo?.role_id == 2) return true; // iris_admin
             if (!this.isAllowedRole(this.authInfo?.role_id)) return false;
             if (this.authInfo?.alpit_license_count <= 0) return false;
             return true;
         },
     },
     data: function() {
         return {
             isValid: false,
             allowedRoleIds: [2,3,4],
             authInfo: null,
         };
     },
     mounted: async function() {
         this.updatePageTitle();
         const response = await axios.get('/authinfo');
         this.authInfo = response.data;
     },
     methods: {
         updatePageTitle() {
             // イマイチな作りへの暫定対応。
             // 作り直し時にheaderは分けるとして、storeを使う感じか。
             if (this.active) {
                 document.querySelector(".page-name").innerHTML = this.caption;
                 document.addEventListener("click", handleClickHeader);
             }
         },
         isAllowedRole(roleId) {
             return this.allowedRoleIds.includes(roleId);
         },
     },
     watch: {
     },
 }
</script>
