<template>
    <select
        class="form-select"
        ref="locationSelect"
        v-model="params.locationSelected"
        @change="handleChange"
        :id="elmId"
        :disabled="isSelectDisabled">
        <option v-if="hasBlankItem" value="" selected>{{ dispLabelAll }}</option>
	<option
            v-for="(location,index) in params.locations"
            :key="location.id"
            :value="location.id"
            :selected="index === 0 && !includeAll">
            {{ makeDispLabel(location) }}
        </option>
    </select>
</template>

<script>
 import { isStringEmpty } from '@/composables/common.js';
 import LocationList from '@/components/LocationList.vue';
 import axios from '@/composables/axios.js';

 export default {
     mixins: [
         LocationList,
     ],
     emits: [
         'handlerChange',
     ],
     computed: {
         isSelectDisabled() {
             if (this.isDisable) return true;
             if (this.fixSingleItem && this.params.locations.length == 1) return true;
             return this.params.locations.length < 1;
         },
     },
     mounted: async function() {
         this.dispLabelAll = this.$_cstr('STRID_SEARCH_ITEM_PULLDOWN_ALL');
         if (!isStringEmpty(this.labelAll)) this.this.dispLabelAll = this.labelAll;
         this.updateLocationList();
     },
     methods: {
         async updateLocationList() {
             this.isDisable = false;
             this.$emit('data-loaded',{location: false});
             await this.getLocationList();
             this.isDisable = false;
             this.bindSumoSelect();
             this.$emit('data-loaded',{location: true});
         },
         async getLocationList() {
             try {
                 this.params.isLocationLoading = true;
                 const response = await axios.get('/location/mylocations',);
                 this.params.locations = response.data;
                 if (this.fixSingleItem && this.params.locations.length == 1) {
                     this.params.locationSelected = this.params.locations[0].id;
                 }
             } catch (error) {
                 console.error('Error fetching message:', error);
             } finally {
                 this.params.isLocationLoading = false;
             }
         },
         handleChange(event) {
             this.$emit('handlerChange',event);
         },
     },
 }
</script>
