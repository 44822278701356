<template>
    <transition :name="transitionName">
        <div
            class="loading"
            v-if="isVisible"
            :style="{ '--fade-duration': `${animationDuration}s` }"
        >
            <div class="loading__inner">
                <div class="loading__content">
                    <span class="spinner"></span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
 export default {
     props: {
         isVisible: {
             type: Boolean,
             required: true,
         },
         animationDuration: {
             type: Number,
             default: 0.3,
         },
     },
     computed: {
         transitionName() {
             return 'fade';
         },
     },
 };
</script>

<style scoped>
 .loading {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: #222;
     z-index: 9999999;
     display: flex;
     justify-content: center;
     align-items: center;
     opacity: 0.6;
 }

 .loading__inner {
     position: absolute;
     width: 100%;
     height: 100%;
 }

 .loading__content {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .spinner {
     width: 75px;
     height: 75px;
     border-width: 2px;
     border-style: solid;
     border-color: rgba(255, 255, 255, 0.1);
     border-top-color: #fff;
     border-radius: 50%;
     animation: spin 1s infinite linear;
 }

 @keyframes spin {
     100% {
         transform: rotate(360deg);
     }
 }

 .fade-enter-active,
 .fade-leave-active {
     transition: opacity var(--fade-duration) ease;
 }

 .fade-enter,
 .fade-leave-to {
     opacity: 0;
 }
</style>
